import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, LinearProgress, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';

import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import AccountTypeForm, {
  ACCOUNT_INIT,
  COVERDELL_ACCOUNT_INIT,
} from '../components/form/newAccount/AccountTypeForm';
import AccountOwnerForm, {
  ACCOUNT_OWNER_INIT,
} from '../components/form/newAccount/AccountOwnerForm';
import { useUser } from '../auth/useUser';
import {
  addTransactionData,
  setSearchResponse,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
  setSelectedAccount,
} from './TransactionReducer';
import ContributionInfoForm, {
  CONTRIBUTION_INFO_INIT,
} from '../components/form/newAccount/ContributionInfoForm';
import DesignatePrimaryBeneficiaries from '../components/beneficiaries/DesignatePrimaryBeneficiaries';
import DesignateSecondaryBeneficiaries from '../components/beneficiaries/DesignateSecondaryBeneficiaries';
import ContributionAllocationForm, {
  ALLOCATION_INIT,
} from '../components/form/newAccount/ContributionAllocationForm';
import { createOrSaveAllocations } from '../api/InvestmentRateApi';
import {
  changeAccountStatus,
  createAccountDocument,
  createOrSaveAccount,
  getAccount,
  getAccountDocument,
} from '../api/AccountApi';
import {
  Account,
  AccountStatus,
  AccountStatusState,
  AccountType,
  TransactionType,
  WorkflowDates,
} from '../api/AccountApi.d';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import { useGlobalContext } from '../auth/useGlobalContext';
import {
  createOrSaveAccountOwner,
  getAccountOwner,
  getOrgAccountOwners,
} from '../api/AccountOwnerApi';
import {
  AccountOwnerSearchResult,
  AccountOwner,
} from '../api/AccountOwnerApi.d';
import {
  AccountContribution,
  ContributionStatus,
} from '../api/ContributionApi.d';
import { Allocation } from '../api/InvestmentRateApi.d';
import { Beneficiaries } from '../api/BeneficiariesApi.d';
import AccountSearchForm, {
  ACCOUNT_SEARCH_INIT,
} from '../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import AccountOwnerSearchResults from '../components/form/accountSearch/AccountOwnerSearchResults';
import {
  createOrSaveContributionInformation,
  getAccountContributions,
} from '../api/ContributionApi';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import SkipChallenge from '../components/steps/SkipChallenge';
import { newAccountDepositHelpTxt } from '../components/form/newAccount/resource.txt';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import { useBeneficiariesReducer } from '../components/beneficiaries/BeneficiariesReducer';
import { IMMDocumentType } from '../api/ESignApi.d';
import SiraPageAlert from '../components/SiraPageAlert';
import { getBeneficiaries } from '../api/BeneficiariesApi';
import { errorMessages } from '../utils/errorhandling.utils';

function AddNewAccount() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const [beneficiariesState] = useBeneficiariesReducer();
  const queryParams = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false);
  const [taxIdToSearch, setTaxIdToSearch] = useState('' as string);
  const [allocations, setAllocations] = useState([] as Array<Allocation>);
  const [pageState] = useTransactionReducer();
  const { accountOwnerId: selectedAccountOwnerId } = pageState.selectedAccount;
  const { accountStatus } = pageState.accountInformation as Account;
  const { query } = pageState.searchResponse;
  const [firstBeneficiary, setFirstBeneficiary] = useState(true as boolean);
  const [secondaryBeneficiary, setSecondaryBeneficiary] = useState(
    false as boolean
  );
  const [contributiionAdded, setContributionAdded] = useState(true as boolean);

  const isAwaiting = [
    AccountStatus.signature,
    AccountStatus.review,
    AccountStatus.submitOwner,
  ].includes(accountStatus);
  const isFinalizeStatus = [AccountStatus.open, AccountStatus.review].includes(
    accountStatus
  );
  const loadingExistingAccount = Boolean(queryParams.get('accountId'));
  // grabbing the account owner id from the query params
  const existingAccountOwner = Boolean(queryParams.get('accountOwnerId'));
  const existingAccountOwnerID = queryParams.get('accountOwnerId');

  const loadingExistingAccountOwner = Boolean(selectedAccountOwnerId);

  const matchingAccountOwnerFilter = (info: AccountOwnerSearchResult) => {
    return (
      info.accountOwner.accountOwnerId ===
      pageState.selectedAccount.accountOwnerId
    );
  };
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();

  // Look up account owners and find an exisiting account owner by taxID then search for it
  async function handleExistingAccountOwner(
    status: number,
    taxIdToMatch: string
  ): Promise<void> {
    setIsLoading(true);

    if (status === 400 && taxIdToMatch) {
      const response = await getOrgAccountOwners(
        user.organizationId,
        user.token,
        user
      );

      // Make sure there's an owner with that SSN first
      const { taxpayerIdNumber: taxIdToLookup = '' } = response.data
        ? response.data.find(
            ({ taxpayerIdNumber }) => taxpayerIdNumber === taxIdToMatch
          )
        : {};

      // Send user to search step with the SSN as the query
      if (taxIdToLookup) {
        if (isMounted) {
          setTaxIdToSearch(taxIdToLookup);
          skipStep(0);
        }
      }
    }

    setIsLoading(false);
  }

  // The reducer is quite flaky, the assumption i am making was that the individual was thinkinig
  // that information might be used some where else so it's best to have access to it anywhere
  // the changes i just added just calls the bene api and gets it instead of relying on the reducer.
  // this gets kicked of in the skip and submit proces of the investment step
  async function getBeneficiaryVersions(): Promise<void> {
    const params = {};

    getBeneficiaries(
      pageState.accountInformation.accountId,
      pageState.accountInformation.accountOwnerId,
      user.organizationId,
      user.token,
      params,
      user
    ).then((res) => {
      if (isMounted) {
        if (res.data.length > 0) {
          setFirstBeneficiary(!(res.data[0].primaryBeneficiaries.length > 0));
          setSecondaryBeneficiary(
            !(res.data[0].secondaryBeneficiaries.length > 0)
          );
        }
      }
    });
  }

  // Revert transaction to pending before saving to allow updates
  const revertToPending = async (): Promise<AccountStatus> => {
    await changeAccountStatus(
      user.organizationId,
      pageState.accountInformation.accountId,
      pageState.accountInformation.accountOwnerId,
      AccountStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        const { accountStatus: newAccountStatus } = res.data;

        addTransactionData({
          accountInformation: {
            ...pageState.accountInformation,
            accountStatus: newAccountStatus,
          } as Account,
        });

        return newAccountStatus;
      })
      .catch(() => {
        addGlobalMessage('Error reverting account status');
      });

    return pageState.accountInformation.accountStatus;
  };

  // Step submit methods
  const setAccountSearchResult = async (result: Account & AccountOwner) => {
    addTransactionData({ selectedAccount: result }, false);
  };

  // Account Owner
  const saveAccountOwner = async (data: AccountOwner) => {
    if (isAwaiting) {
      await revertToPending();
    }

    await createOrSaveAccountOwner(data, user.organizationId, user.token, user)
      .then((res) => {
        const { data: accountOwnerInformation } = res;

        if (isMounted) {
          addTransactionData({ accountOwnerInformation });
        }
      })
      .catch((err) => {
        const { response: { status = 0 } = {} } = err;

        // Match the tax id with existing account owners and search
        handleExistingAccountOwner(status, data.taxpayerIdNumber);

        addGlobalMessage(
          errorMessages(err) || 'Error saving account owner information'
        );
      });
  };
  // Account Save
  const saveAccount = async (data: Account) => {
    let merged = data; // Merge a reverted status immediately when saving
    const { accountOwnerId = '' } = pageState.accountOwnerInformation;
    delete merged.responsibleCountry;

    merged.accountOwnerId = accountOwnerId;
    if (data.accountType !== AccountType.esa) {
      merged = { ...merged, ...COVERDELL_ACCOUNT_INIT };
    }

    if (isAwaiting) {
      const newAccountStatus = await revertToPending();
      merged.accountStatus = newAccountStatus;
    }

    await createOrSaveAccount(
      merged,
      user.organizationId,
      accountOwnerId,
      user.token,
      user
    )
      .then((res) => {
        const { data: accountInformation } = res;

        if (isMounted) {
          addTransactionData({ accountInformation });
        }
        const transactionData: RowDefinition = {
          accountId: res.data.accountId,
          accountOwnerId: res.data.accountOwnerId,
          transactionType: TransactionType.account,
        };
        setUnsavedChanges(transactionData);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error saving account information'
        );
      });
  };

  // Contribution Save
  const saveContribution = async (data: AccountContribution) => {
    if (isAwaiting) {
      await revertToPending();
    }

    await createOrSaveContributionInformation(
      data,
      user.organizationId,
      pageState.accountOwnerInformation.accountOwnerId,
      pageState.accountInformation.accountId,
      pageState.contributionInformation.contributionId,
      user.token,
      user
    )
      .then((res) => {
        const { data: contributionInformation } = res;

        if (isMounted) {
          addTransactionData({ contributionInformation });
          setContributionAdded(false);
        }
      })
      .catch((err) => {
        setContributionAdded(false);
        addGlobalMessage(
          errorMessages(err) || 'Error saving contribution information'
        );
      });
  };

  // Contribution Allocations Save
  const saveAllocations = async (data: any) => {
    if (isAwaiting) {
      await revertToPending();
    }

    await createOrSaveAllocations(
      data,
      user.organizationId,
      pageState.accountInformation.accountId,
      pageState.accountOwnerInformation.accountOwnerId,
      pageState.contributionInformation.contributionId,
      user.token,
      user
    )
      .then((res) => {
        const { data: newAllocations } = res;

        if (isMounted) {
          setAllocations(newAllocations);
          getBeneficiaryVersions();
          skipStep(6);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error saving allocation information'
        );
      });
  };

  // Beneficiaries Save
  const saveBeneficiaries = async (beneficiaryInformation: Beneficiaries) => {
    if (isAwaiting) {
      await revertToPending();
    }

    addTransactionData({ beneficiaryInformation });
  };

  // Clear out any account owner info if user selected one but went back to create a new owner
  const clearAndCreateNewAccountOwner = () => {
    setSelectedAccount({});
    addTransactionData({ accountOwnerInformation: ACCOUNT_OWNER_INIT }, false);
    skipStep(pageState.activeStep + 2);
  };

  // Clear out any account info if user selected one but went back to create a new account
  const clearAndCreateNewAccount = () => {
    addTransactionData({
      accountInformation: { ...ACCOUNT_INIT, accountOwnerId: '' },
    });
  };

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = () => {
    if (accountStatus === AccountStatus.pending) {
      addTransactionData(
        {
          accountInformation: { accountStatus: AccountStatus.signature },
        },
        false
      );
    }
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaiting || isFinalizeStatus
      ? getAccountDocument(
          user.organizationId,
          pageState.accountInformation.accountOwnerId,
          pageState.accountInformation.accountId,
          user.token,
          user
        )
      : createAccountDocument(
          user.organizationId,
          pageState.accountInformation.accountOwnerId,
          pageState.accountInformation.accountId,
          user.token,
          user
        );
  };

  // Advance the account status to the next
  async function advanceAccountStatus(finalizeWorkflow: boolean) {
    const response = { errorMessage: null, data: null };

    await changeAccountStatus(
      user.organizationId,
      pageState.accountInformation.accountId,
      pageState.accountInformation.accountOwnerId,
      AccountStatusState.next,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        response.data = res.data;
        if (isMounted) {
          addTransactionData({ accountInformation: res.data }, false);

          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch((err) => {
          addGlobalMessage(
            errorMessages(err) || 'Error finalizing Account'
          );
        if (isMounted) {
          rejectTransaction();
        }
      });
  }

  // Get the accountOwner for the account loaded
  async function fetchAndSetAccountOwner(
    accountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          addTransactionData({ accountOwnerInformation: res.data });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account owner'
        );
      });
  }

  // Look up the first PENDING contribution for the looked up account
  async function fetchAndSetContributionInfo(
    contributionAccountId: string,
    contributionAccountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccountContributions(
      contributionAccountId,
      contributionAccountOwnerId,
      user.organizationId,
      user.token,
      user,
      [ContributionStatus.pending, ContributionStatus.signature]
    )
      .then((res) => {
        const [firstPendingAccountContribution] = res.data;

        if (isMounted && firstPendingAccountContribution) {
          addTransactionData({
            contributionInformation: firstPendingAccountContribution,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) ||
            'Could not fetch the preselected contribution info'
        );
      });
  }

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccountData(
    accountId: string,
    accountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccount(
      queryParams.get('accountId') || accountId,
      queryParams.get('accountOwnerId') || accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(async (res) => {
        const skipToEnd = [
          AccountStatus.signature,
          AccountStatus.review,
          AccountStatus.submitOwner,
        ].includes(res.data.accountStatus);

        if (isMounted) {
          addTransactionData({ accountInformation: res.data });
          await fetchAndSetAccountOwner(res.data.accountOwnerId);
          await fetchAndSetContributionInfo(
            res.data.accountId,
            res.data.accountOwnerId
          );
          if (skipToEnd) {
            skipStep(8);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account'
        );
      });
  }

  // Handle selecting a pending account with the first step
  const setAccountResultAndFetchAccount = async (
    data: Account & AccountOwner
  ) => {
    const { accountStatus: selectedAccountResultStatus } = data;
    if (
      [AccountStatus.pending, AccountStatus.signature].includes(
        selectedAccountResultStatus
      )
    ) {
      setAccountSearchResult(data);
      fetchAndSetAccountData(data.accountId, data.accountOwnerId);
    }
  };

  const newAccountSteps: Array<TransactionStep> = [
    {
      label: 'Enter name or tax ID',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm
                  setResponse={setSearchResponse}
                  initialValues={{
                    ...ACCOUNT_SEARCH_INIT,
                    query: taxIdToSearch,
                  }}
                  submitOnMount
                />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <>
              <AccountOwnerSearchResults
                response={pageState.searchResponse}
                onResultClick={setAccountSearchResult}
              />
              <Box mt={2}>
                <Button
                  data-qa="add-account-owner-button"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={clearAndCreateNewAccountOwner}
                >
                  Add account owner
                </Button>
              </Box>
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'Find or Create an Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          {query && (
            <>
              <AccountSearchResults
                response={pageState.searchResponse}
                onResultClick={setAccountResultAndFetchAccount}
                filterFunction={matchingAccountOwnerFilter}
              />
              <Box mt={2}>
                <Button
                  data-qa="add-account-button"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={clearAndCreateNewAccount}
                >
                  Add account
                </Button>
              </Box>
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'Enter Account Owner Details',
      stepContent: (
        <Box mt={5} mb={3}>
          <AccountOwnerForm
            initialValues={{
              ...ACCOUNT_OWNER_INIT,
              ...pageState.accountOwnerInformation,
            }}
            onSubmit={saveAccountOwner}
            isEditing={Boolean(
              loadingExistingAccountOwner ||
                loadingExistingAccount ||
                existingAccountOwner
            )}
          />
        </Box>
      ),
    },
    {
      label: 'Select Account Type',
      stepContent: (
        <Box mt={5} mb={3}>
          <AccountTypeForm
            ownerAccountResults={pageState.selectedAccount.accounts}
            initialValues={{
              ...ACCOUNT_INIT,
              ...pageState.accountInformation,
            }}
            onSubmit={saveAccount}
            accountOwner={pageState.accountOwnerInformation}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Deposit Information',
      stepContent: (
        <SkipChallenge
          onSkip={() => {
            skipStep(pageState.activeStep + 2);
            getBeneficiaryVersions();
          }} // Also skip investments step
          skipText="Skip"
          acceptText="Make Initial Deposit"
          shouldDisplay={!pageState.contributionInformation.contributionId}
        >
          <ContributionInfoForm
            account={pageState.accountInformation}
            initialValues={{
              ...CONTRIBUTION_INFO_INIT,
              ...pageState.contributionInformation,
            }}
            onSubmit={saveContribution}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Select Investment Options',
      stepContent: (
        <SkipChallenge
          onSkip={() => {
            getBeneficiaryVersions();
            skipStep(pageState.activeStep + 1);
          }}
          skipText="Skip"
          acceptText="Investment Options"
        >
          <ContributionAllocationForm
            existingAllocations={[...ALLOCATION_INIT, ...allocations]}
            contributionInfo={pageState.contributionInformation}
            onSubmit={(data) => {
              saveAllocations(data);
            }}
            accountOwnerId={pageState.contributionInformation.accountOwnerId}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Name Primary Beneficiaries',
      stepContent: (
        <SkipChallenge
          onSkip={(skipvalue) => skipStep(pageState.activeStep + skipvalue)}
          skipText="Skip"
          acceptText="Add Beneficiaries"
          skipModal={
            newAccountDepositHelpTxt.primaryBeneficiary.skipBenefeciaries
          }
          skipModalTitle="Primary Beneficiary"
          shouldDisplay={firstBeneficiary}
        >
          {/* add new prop here to pass in boolean value to copy ri info */}
          <DesignatePrimaryBeneficiaries
            state={beneficiariesState}
            onSave={saveBeneficiaries}
            account={pageState.accountInformation}
            accountOwner={pageState.accountOwnerInformation}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Name Contingent Beneficiaries',
      stepContent: (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Add Beneficiaries"
          shouldDisplay={secondaryBeneficiary}
        >
          <DesignateSecondaryBeneficiaries
            state={beneficiariesState}
            onSave={saveBeneficiaries}
            account={pageState.accountInformation}
            accountOwner={pageState.accountOwnerInformation}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.accountInformation}
            allowSignature={isAwaiting}
            submitName="Done"
            getDocument={viewDocument}
            onEsignClick={completeTransaction}
            onGetDocument={handleCreateDocument}
            hideStepButtonBar={pageState.completed}
            onSubmit={async () => {
              if (isAwaiting) {
                await advanceAccountStatus(true);
              }
            }}
            documentType={IMMDocumentType.account}
            transactionName={contributiionAdded ? 'New account' : 'Account'}
            transactionStatus={pageState.accountInformation.accountStatus}
          />
          {isFinalizeStatus &&
            (pageState.completed || pageState.rejected) &&
            (contributiionAdded ? (
              <SiraPageAlert
                pageStatus={pageState.rejected}
                transactionName="New account"
                accountId={pageState.accountInformation.accountId}
                accountOwnerId={pageState.accountInformation.accountOwnerId}
                linklabel="Request a Rollover/Transfer for this account"
              />
            ) : (
              <SiraPageAlert
                pageStatus={pageState.rejected}
                transactionName="Account"
                accountId={pageState.accountInformation.accountId}
                accountOwnerId={pageState.accountInformation.accountOwnerId}
              />
            ))}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (user.token && user.organizationId) {
      if (isMounted) {
        if (loadingExistingAccount) {
          fetchAndSetAccountData(
            pageState.selectedAccount.accountId,
            pageState.selectedAccount.accountOwnerId
          );
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    if (loadingExistingAccountOwner || existingAccountOwner) {
      fetchAndSetAccountOwner(selectedAccountOwnerId || existingAccountOwnerID);
      // after fetching the account owner, we need to go to the accountowner step
      if (!loadingExistingAccount && existingAccountOwner) {
        skipStep(2);
      }
    }
  }, [selectedAccountOwnerId, existingAccountOwnerID]);

  return (
    <Layout>
      <FormPaper>
        <Box width="100%">
          <Typography variant="overline">Transactions</Typography>
          <Typography variant="h1" color="secondary" gutterBottom>
            Open New Account
          </Typography>
          <Typography variant="body1">
            Find or create a new account owner and select the new account type
            to enter the details for the account.
          </Typography>
          {isLoading ? (
            <Box mt={4}>
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <Box mt={5}>
              <TransactionStepper
                steps={
                  organization.displayInvestments
                    ? newAccountSteps
                    : newAccountSteps.filter(
                        (step) => step.label !== 'Select Investment Options'
                      )
                }
                activeStep={pageState.activeStep}
                isLoading={isLoading}
                onStepClick={(index) => {
                  if (
                    !loadingExistingAccount ||
                    (loadingExistingAccount && index > 1)
                  ) {
                    skipStep(index);
                  }
                }}
              />
            </Box>
          )}
          {UnsavedChangesPrompt}
        </Box>
      </FormPaper>
    </Layout>
  );
}

export default AddNewAccount;
